/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, hover } from 'glamor'
import { rem } from 'polished'
import { colors, grid, breakpoints, fonts } from './../../theme'

const {
  NEUTRAL_COLOR_25,
  NEUTRAL_COLOR_50,
  NEUTRAL_COLOR_65,
  NEUTRAL_COLOR_90,
  BRAND_COLOR_50,
} = colors
const { SMALL_BREAKPOINT, MEDIUM_BREAKPOINT, LARGE_BREAKPOINT, HUGE_BREAKPOINT } = breakpoints
const { LIBRE_FRANKLIN_REGULAR, LIBRE_FRANKLIN_SEMIBOLD } = fonts

const { row, column } = grid

const styles = StyleSheet.create({
  container: merge({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  title: merge(
    {
      marginTop: rem(32),
      marginBottom: rem(0),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(48),
      marginBottom: rem(0),
    }),
    media(HUGE_BREAKPOINT, {
      marginTop: rem(64),
    }),
  ),
  paragraph: {
    maxWidth: rem(550),
    textAlign: 'center',
    color: NEUTRAL_COLOR_50,
  },
  articles: merge(
    {
      marginTop: rem(18),
      ...row(),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(36),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(42),
    }),
  ),
  article: merge(
    {
      ...column(1),
      marginBottom: rem(36),
    },
    media(SMALL_BREAKPOINT, {
      ...column(1 / 2),
      marginBottom: rem(48),
    }),
    media(MEDIUM_BREAKPOINT, {
      ...column(1 / 3),
    }),
  ),
  seeMoreContainer: {
    width: '100%',
    marginTop: rem(50),
    marginBottom: rem(100),
    display: 'flex',
    justifyContent: 'center',
  },
  seeMore: merge(
    {
      backgroundColor: 'transparent',
      color: NEUTRAL_COLOR_90,
      border: `1px solid ${NEUTRAL_COLOR_25}`,
    },
    hover({
      backgroundColor: 'transparent',
      color: NEUTRAL_COLOR_90,
      border: `1px solid ${NEUTRAL_COLOR_50}`,
    }),
    hover({
      backgroundColor: 'transparent',
      color: NEUTRAL_COLOR_90,
      border: `1px solid ${NEUTRAL_COLOR_65}`,
    }),
  ),
  pagination: merge(
    {
      ...LIBRE_FRANKLIN_REGULAR,
      color: NEUTRAL_COLOR_90,
      fontSize: rem(16),
      paddingRight: rem(5),
      paddingLeft: rem(5),
      cursor: 'pointer',
      textDecoration: 'none',
    },
    hover({
      textDecoration: 'underline',
    }),
  ),
  paginationPrev: merge(
    {
      display: 'flex',
      alignItems: 'center',
      ...LIBRE_FRANKLIN_REGULAR,
      color: NEUTRAL_COLOR_90,
      marginRight: rem(20),
      fontSize: rem(16),
      cursor: 'pointer',
      textDecoration: 'none',
    },
    hover({
      textDecoration: 'underline',
    }),
  ),
  paginationNext: merge(
    {
      display: 'flex',
      alignItems: 'center',
      ...LIBRE_FRANKLIN_REGULAR,
      color: NEUTRAL_COLOR_90,
      marginLeft: rem(20),
      fontSize: rem(16),
      cursor: 'pointer',
      textDecoration: 'none',
    },
    hover({
      textDecoration: 'underline',
    }),
  ),
  iconPrev: merge({
    marginRight: rem(12),
    transform: 'rotate(90deg)',
  }),
  iconNext: merge({
    marginLeft: rem(12),
    transform: 'rotate(-90deg)',
  }),
  pageSelected: merge({
    ...LIBRE_FRANKLIN_SEMIBOLD,
    color: BRAND_COLOR_50,
    textDecoration: 'underline',
  }),
})

export default styles
